import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/enterprise/enterprise-meta.png'
import AllWorkIcon from '../assets/images/product/blurb-icons/all-work.png'
import PatternsIcon from '../assets/images/product/blurb-icons/anti-patterns.png'
import PuzzleIcon from '../assets/images/product/blurb-icons/collaboration.png'
import GearIcon from '../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../assets/images/product/blurb-icons/lock.png'
import SlideAndDiceIcon from '../assets/images/product/blurb-icons/slide-and-dice.png'
import TakeActionIcon from '../assets/images/product/blurb-icons/take-action.png'
import TrendsIcon from '../assets/images/product/blurb-icons/trends.png'
import ZoomIcon from '../assets/images/product/blurb-icons/zoom.png'
import tag from '../assets/images/tags/enterprise-tag.svg'
import FAQ from '../components/EnterpriseFAQ'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Box from '../components/primitives/Box'
import Blurb from '../components/system/Blurb'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/enterprise/enterprise-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(
      relativePath: { eq: "images/enterprise/enterprise-insights-lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1247)
      }
    }
    lead2: file(
      relativePath: { eq: "images/enterprise/investment-balance-lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1370)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/enterprise/issue-pop-up.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/enterprise/developer-overview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/enterprise/review-prs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const WorkLogPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Swarmia for enterprises"
      variant="dark"
      isNew
      description="Swarmia gives software organizations with thousands of developers the insights they need to eliminate bottlenecks and quantify the business impact of engineering."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Lead your engineering organization with confidence</>}
        content="Swarmia gives software organizations with thousands of developers the insights they need to eliminate bottlenecks and quantify the business impact of engineering."
        backgroundImage={getImage(data.backgroundImage)!}
        secondaryButton={null}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Get a bird’s eye view into the whole organization"
        content="Bring your organizational hierarchy to Swarmia and get a high-level overview of how different groups and teams are doing."
        link={{
          title: 'Read more about engineering metrics',
          href: '/product/engineering-metrics/',
        }}
        alt="Visualize work with organizational insights"
        image={getImage(data.lead)}
      />
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Identify trends and signals"
            text="Quickly see which teams need your support and guidance."
            icon={TrendsIcon}
          />,
          <Blurb
            key="2"
            title="Slice and dice the data"
            text="Drill into metrics behind the aggregates to understand what’s really going on."
            icon={SlideAndDiceIcon}
          />,
          <Blurb
            key="3"
            title="Take action"
            text="Use data to start conversations that drive the teams to improve."
            icon={TakeActionIcon}
          />,
        ]}
      />
      <LeadBlock
        heading="Quantify the business impact of engineering"
        content="Communicate the value of engineering work to all stakeholders and spot opportunities to maximize the business impact of software development."
        link={{
          title: 'Read more about investment balance',
          href: '/product/investment-balance/',
        }}
        alt="Investment Balance"
        image={getImage(data.lead2)}
      />
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Maximize business impact"
            text="Get the data you need to communicate the dollar value of engineering initiatives."
            icon={ZoomIcon}
          />,
          <Blurb
            key="2"
            title="Identify hidden patterns"
            text="Spot opportunities to improve collaboration, reduce tech debt, or reorganize teams."
            icon={PatternsIcon}
          />,
          <Blurb
            key="3"
            title="A tool for your platform team"
            text="Give your platform team a way to measure the success of org-wide productivity work."
            icon={PuzzleIcon}
          />,
        ]}
      />
      <QuoteBlock person="alex" />
      <LeadBlock
        heading="Empower teams and team leads"
        content="Give engineering managers and teams the visibility and tools they need to drive better conversations and make informed decisions."
      />
      <MosaicBlock
        title="Analyze work in progress"
        content="Use the Work Log to look at all the ongoing work, recognize activity anti-patterns, and remove blockers like multitasking or siloing."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Support and coach individuals"
        content="Instead of trying to reduce developers into a single metric, use Developer Overview to enrich 1:1s and career development discussions."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        imageFrame={false}
      />
      <MosaicBlock
        title="Automate feedback loops"
        content="Encourage teams to adopt Working Agreements and subscribe to Slack notifications to speed up code reviews and improve collaboration."
        image={getImage(data.mosaicLeft2)!}
      />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: security, access control, and APIs that meet enterprise expectations with flexible configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to Okta SSO and export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We’re committed to performing security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Ready to start quantifying the business impact of engineering?"
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/size-age-culture-productivity/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default WorkLogPage
